import { TipoDePagoTypes } from "../../../../utils/data";
export var getRowFields = function getRowFields(poliza) {
  return [{
    label: "Número Cuotas",
    value: poliza === null || poliza === void 0 ? void 0 : poliza.number_of_cuotes,
    tipo_de_pagos: [TipoDePagoTypes.PAC, TipoDePagoTypes.PAT, TipoDePagoTypes.AVISO_DE_VENCIMIENTO, TipoDePagoTypes.ONE_CLICK]
  }, {
    label: "Valor Cuotas",
    value: poliza === null || poliza === void 0 ? void 0 : poliza.first_cuote,
    tipo_de_pagos: [TipoDePagoTypes.PAC, TipoDePagoTypes.PAT, TipoDePagoTypes.AVISO_DE_VENCIMIENTO, TipoDePagoTypes.ONE_CLICK]
  }, {
    label: "Fecha Primer Vencimiento",
    value: poliza === null || poliza === void 0 ? void 0 : poliza.first_cuote,
    tipo_de_pagos: [TipoDePagoTypes.PAC, TipoDePagoTypes.PAT, TipoDePagoTypes.AVISO_DE_VENCIMIENTO, TipoDePagoTypes.ONE_CLICK]
  }, {
    label: "Pagado Previamente?",
    value: poliza !== null && poliza !== void 0 && poliza.is_prepaid ? "Si" : "No",
    tipo_de_pagos: [TipoDePagoTypes.CONTADO]
  }, {
    label: "Mostrar Pago por Transferencia?",
    value: poliza !== null && poliza !== void 0 && poliza.show_transfer_payment ? "Si" : "No",
    tipo_de_pagos: [TipoDePagoTypes.CONTADO, TipoDePagoTypes.AVISO_DE_VENCIMIENTO]
  }, {
    label: "Pagado Previamente?",
    value: poliza !== null && poliza !== void 0 && poliza.is_prepaid ? "Si" : "No",
    tipo_de_pagos: [TipoDePagoTypes.CONTADO]
  }];
};