import { getDifferenceInDays } from "../../../../utils/datetime";
export var aseguradoAmountFormula = function aseguradoAmountFormula(poliza, amountChangeDate, amountChangeDifference) {
  var _poliza$broker_inform, _poliza$broker_inform2;

  // When both values exist, proceed to calculate the new affectNetBonus and exemptNetBonus
  var affectNetBonus = ((_poliza$broker_inform = poliza.broker_information) === null || _poliza$broker_inform === void 0 ? void 0 : _poliza$broker_inform.affects_net_bonus) || 0;
  var exemptNetBonus = ((_poliza$broker_inform2 = poliza.broker_information) === null || _poliza$broker_inform2 === void 0 ? void 0 : _poliza$broker_inform2.exempt_net_bonus) || 0;
  var initialAseguradoAmount = poliza.total_asegurado_amount || 0;
  var affectRate = affectNetBonus / initialAseguradoAmount * 1000;
  var exemptRate = exemptNetBonus / initialAseguradoAmount * 1000;
  var dateRate = getDifferenceInDays(poliza.finish_date, poliza.start_date);
  var amountDateRate = getDifferenceInDays(amountChangeDate, poliza.finish_date);
  var newAffectNetBonus = amountChangeDifference * affectRate / 1000 / dateRate * amountDateRate; // En Excel: C19 = (C18 * E10) / 1000 / (C5-C6) ) * (C6-C17)

  var newExemptNetBonus = amountChangeDifference * exemptRate / 1000 / dateRate * amountDateRate; // En Excel: C20 = (C18 * E11) / 1000 / (C5-C6) ) * (C6-C17)

  return {
    newAffectNetBonus: newAffectNetBonus,
    newExemptNetBonus: newExemptNetBonus
  };
};